<template>
	<div class="trends">
		<div class="title">{{ groupDetails.group.name }}</div>
		<score size="big" :score="myScore.score" :numCorrect="myScore.numCorrect" :numChoices="myScore.numChoices" :percentage="myScore.percentage"></score>
		<score-graph :scoreLines="userScores"></score-graph>
		<!-- Chat -->
		<input
			ref="newChat"
			v-model="chatText"
			type="text"
			class="inputChat"
			placeholder="Talk the talk..."
		/><br />
		<button class="goButton" @click="addChat()">Talk the Talk</button>
		<div v-for="chat in groupDetails.chats" :key="chat.id" class='previousChats'><b style="font-size:12px">{{ tinyDate(chat.createdAt) }}</b>
			<b>{{ chat.userName }}</b> - {{ chat.talk }}
		</div>
		<u style="cursor:pointer" @click="goToChat()" v-if="groupDetails.chats.length >= 10">Show More</u>
		<hr />
		<!-- User List -->
		<div style="font-size:18px;" :style="`color:${colors[idx]}`" v-for="(user, idx) in groupDetails.users" :key="`user-${user.id}`">
			<b><u>{{ user.userName }}'s Expertise</u></b>
			<i v-if="user.score.numChoices == 0"> (hasn't scored yet)</i>
			<template v-if="user.score.numChoices > 0">
				<score size="big" :score="user.score.score" :numCorrect="user.score.numCorrect" :numChoices="user.score.numChoices" :percentage="user.score.percentage"></score>
				<table width="100%" style="border-bottom:1px dotted silver">
					<tr>
						<td style="width:50%;height:100px;" >
							<template v-if="user.bestActor">
								<b>{{ user.bestActor.name }}</b>
								<score size="small" :score="user.bestActor.score" :numCorrect="user.bestActor.numCorrect" :numChoices="user.bestActor.numChoices" :percentage="user.bestActor.percentage"></score>
								<br />
								<div style="position: relative;width:100%">
									<div style="position: absolute;top: 0;left: 0;width:100%;">
										<score-graph height="100px" :scoreLines="[{color: colors[idx], scores: user.bestActor.scores}]"></score-graph>
									</div>
									<div style="position: absolute;top: 0px;left: 42px; z-index=10;">
										<img class="best-image" :src="user.bestActor.imageURL" />
									</div>
								</div>
							</template>
						</td>
						<td style="width:50%">
							<template v-if="user.bestInfluencer">
								<b>{{ user.bestInfluencer.name }}</b>
								<score size="small" :score="user.bestInfluencer.score" :numCorrect="user.bestInfluencer.numCorrect" :numChoices="user.bestInfluencer.numChoices" :percentage="user.bestInfluencer.percentage"></score>
								<br />
								<score-graph height="100px" :scoreLines="[{color: colors[idx], scores: user.bestInfluencer.scores}]"></score-graph>
							</template>
						</td>
					</tr>
				</table>
			</template>
		</div>
		<div class="bottom-spacer" />
	</div>
</template>

<script>
import ScoreGraph from "@/components/ScoreGraph";
import Score from "@/components/Score";

export default {
	name: "Group",
	components: {
		Score,
		ScoreGraph
	},
	data() {
		return {
			colors: ["#0095e6", "#ea9770", "#ffd670", "#70d6ff", "#6a2317", "#ca1bad", "#3f0dc4", "#fecda4", "#2bff70", "#ff7006", "#008800", "#ff0000", "#badca1", "#654321", "#789abc", "165f9d", "#b6af6d", "#1a535c"],
			chatText: "",
			myScore: {},
			userScores: [],
			groupDetails: {
				group: {name: ""},
				chats: []
			}
		};
	},
	created() {
		this.loadData();
	},
	methods: {
		loadData: async function () {
			this.groupDetails = JSON.parse(localStorage.getItem(`group-${this.$route.params.id}-details`));
			this.groupDetails = await this.$services.get(`groups/${this.$route.params.id}/details`);
			this.myScore = (this.groupDetails.users.find(u => u.id == this.$services.me.id)).score;
			this.userScores = this.groupDetails.users.filter(u => u.score.numChoices > 0).map((u, idx) => {
				return {color: this.colors[idx], scores: u.scores}
			})
			localStorage.setItem(`group-${this.$route.params.id}-details`, JSON.stringify(this.groupDetails));
		},
		addChat: async function () {
			this.chatText = this.chatText.trim();
			if (this.chatText.length > 0) {
				this.groupDetails.chats = await this.$services.createOne(`groups/${this.groupDetails.group.id}/chat`, {text: this.chatText});
				this.chatText = "";
			}
		},
		goToChat: function () {
			this.$router.push(`/group/${this.groupDetails.group.id}/chat`)
		},
		tinyDate: function (dt) {
			const date = new Date(dt);
			return `${date.getMonth() + 1}/${date.getDate()} `;
		}
	}
}

</script>

<style scoped>
td {
	vertical-align: top;
}

.best-image {
	height: 40px;
}

.inputChat {
	margin: 2px 2vw 0px 2vw;
	font-size: 24px;
	padding: 3px;
	border-radius: 5px;
	width: 94vw;
	color: black;
}

.previousChats {
	font-size: 14px;
	margin: 5px;
}
</style>
